<script>
import ListItem from "@/components/common/ListItem.vue";
import { user } from "@/api/factory.js";

export default {
  components: { ListItem },

  props: {
    selectedWorkspace: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      users: [],
      selectedUsers: [],
    };
  },

  watch: {
    selectedWorkspace: {
      handler() {
        if (this.selectedWorkspace && this.selectedWorkspace.userList.length) {
          this.selectedUsers = this.selectedWorkspace.userList.map((user) => ({
            id: user.userId,
            label: user.email,
            value: user.email,
          }));
        } else {
          this.selectedUsers = [];
        }
      },
    },
  },

  created() {
    this.getUsers();
  },

  methods: {
    async getUsers() {
      const { error, payload } = await user.getUserList();
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (!payload) {
        return;
      }
      this.users = payload.map((user) => ({
        id: user.id,
        label: user.value,
        value: user.value,
      }));
    },

    selectUser(user) {
      const userIdx = this.selectedUsers.findIndex(
        (_user) => _user.id === user.id
      );

      if (userIdx > -1) {
        this.selectedUsers.splice(userIdx, 1);
        this.$emit("remove", user.value);
      } else {
        this.selectedUsers.push(user);
        this.$emit("add", user.value);
      }
    },

    isSelected(user) {
      if (!this.selectedUsers || !this.selectedUsers.length) {
        return false;
      }

      const userIdx = this.selectedUsers.findIndex(
        (_user) => _user.id === user.id
      );

      return userIdx > -1;
    },
  },
};
</script>

<template>
  <BaseActionButton is-flat icon="mdi-account-plus" no-border>
    <q-menu
      transition-show="scale"
      transition-hide="scale"
      anchor="bottom right"
      self="top right"
    >
      <BaseScrollbar height="296px" width="360px" class="q-py-sm">
        <ListItem
          v-for="user in users"
          :key="user.id"
          :icon="
            isSelected(user)
              ? 'mdi-checkbox-marked'
              : 'mdi-checkbox-blank-outline'
          "
          :is-selected="isSelected(user)"
          :label="user.label"
          @click="selectUser(user)"
        />
      </BaseScrollbar>
    </q-menu>
  </BaseActionButton>
</template>

<style lang="scss" scoped></style>
