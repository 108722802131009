<script>
import UserList from "./UserList.vue";

export default {
  name: "ChatHeader",

  components: { UserList },

  props: {
    selectedWorkspace: {
      type: Object,
      required: true,
    },
    tabs: {
      type: Array,
      required: true,
    },
    selectedTab: {
      type: String,
      required: true,
    },
  },

  methods: {
    toggle() {
      const target = document.getElementById("workspace-overview");
      this.$q.fullscreen.toggle(target);
    },
  },
};
</script>

<template>
  <div class="chat-header">
    <div class="tabs">
      <div
        v-for="tab in tabs"
        :key="tab"
        class="tab"
        :class="{ selected: selectedTab === tab }"
        @click="$emit('select', tab)"
      >
        {{ tab }}
      </div>
    </div>
    <BaseActionButton is-flat icon="mdi-fullscreen" no-border @click="toggle" />
    <UserList
      :selected-workspace="selectedWorkspace"
      @add="(user) => $emit('add', user)"
      @remove="(user) => $emit('remove', user)"
    />
  </div>
</template>

<style lang="scss" scoped>
.chat-header {
  border-radius: 4px;
  background-color: var(--component-bg-color);
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 12px 16px;

  .tabs {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 4px;

    .tab {
      padding: 10px 16px;
      border-radius: 4px;
      color: var(--body-text-color-muted);

      &:hover {
        color: var(--body-text-color-muted-hover);
        cursor: pointer;
      }

      &.selected {
        font-weight: 500;
        color: var(--body-text-color);
      }
    }
  }
}

.theme-light {
  .selected {
    background-color: var(--hover-bg-color-inverted);
  }

  .tab:hover {
    background-color: var(--hover-bg-color-inverted);
  }
}

.theme-dark {
  .selected {
    background-color: var(--hover-bg-color);
  }

  .tab:hover {
    background-color: var(--hover-bg-color);
  }
}
</style>
