<script>
export default {
  name: "WorkspaceList",

  props: {
    value: {
      type: Object,
      required: true,
    },
    workspaces: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<template>
  <div id="workspace-list">
    <BaseScrollbar
      :height="`calc(100vh - ${$q.fullscreen.isActive ? '32px' : '224px'})`"
      class="q-pa-md"
    >
      <div
        v-for="workspace in workspaces"
        :key="workspace.id"
        class="workspace"
        :class="{ selected: value.id === workspace.id }"
        @click="$emit('input', workspace)"
      >
        <div class="name">{{ workspace.name }}</div>
        <div v-if="workspace.unreadCount" class="unreadCount">
          {{ workspace.unreadCount }}
        </div>
      </div>
    </BaseScrollbar>

    <q-inner-loading :showing="isLoading">
      <q-spinner color="secondary" size="3em" />
    </q-inner-loading>
  </div>
</template>

<style lang="scss" scoped>
#workspace-list {
  width: 256px;
  background-color: var(--component-bg-color);
  border-radius: 4px;
  position: relative;

  .workspace {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    border-radius: 4px;
    margin-bottom: 4px;

    &:hover {
      background-color: var(--hover-bg-color-inverted);
      cursor: pointer;
    }

    &.selected {
      font-weight: 500;
    }

    .unreadCount {
      font-weight: 600;
      color: var(--secondary);
    }
  }
}

.theme-light .selected {
  background-color: var(--hover-bg-color-inverted);
}

.theme-dark .selected {
  background-color: var(--hover-bg-color);
}
</style>
