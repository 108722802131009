<script>
import WorkspaceList from "./workspace-overview/WorkspaceList.vue";
import ChatBox from "./workspace-overview/ChatBox.vue";
import ActivityFeed from "./workspace-overview/ActivityFeed.vue";
import { workspaceNew } from "@/api/factory.js";

export default {
  name: "WorkspacesOverview",

  components: { WorkspaceList, ChatBox, ActivityFeed },

  data() {
    return {
      selectedWorkspace: {},
      isLoading: false,
      workspaces: [],
    };
  },

  mounted() {
    this.getWorkspaces();
  },

  methods: {
    async getWorkspaces(refresh = false) {
      this.isLoading = !refresh && true;
      const { error, payload } = await workspaceNew.getWorkspaceList();
      this.isLoading = false;

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload.length) {
        this.workspaces = payload.map((workspace) => ({
          id: workspace.id,
          name: workspace.name,
          unreadCount: workspace.inboxCount,
          repositoryId: workspace.repositoryId,
          userList: workspace.userList,
        }));

        this.selectedWorkspace = Object.keys(this.selectedWorkspace).length
          ? this.selectedWorkspace
          : this.workspaces[0];
      }
    },
  },
};
</script>

<template>
  <div
    id="workspace-overview"
    :style="{ padding: $q.fullscreen.isActive ? '16px' : 0 }"
  >
    <WorkspaceList
      v-model="selectedWorkspace"
      :workspaces="workspaces"
      :is-loading="isLoading"
    />
    <ChatBox
      :selected-workspace="selectedWorkspace"
      @refresh="getWorkspaces(true)"
    />
    <ActivityFeed :selected-workspace="selectedWorkspace" />
  </div>
</template>

<style lang="scss" scoped>
#workspace-overview {
  background-color: var(--body-bg-color);
  display: flex;
  gap: 16px;
  min-height: calc(100vh - 224px);
  margin-top: 20px;
}
</style>
