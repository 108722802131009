<!-- eslint-disable vue/no-v-html -->
<script>
import { workspaceNew } from "@/api/factory.js";

export default {
  props: {
    selectedWorkspace: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      tabs: ["Mentions me", "Following"],
      selectedTab: "Mentions me",
      isLoading: false,
      activityFeed: [],
    };
  },

  watch: {
    selectedWorkspace: {
      handler() {
        if (this.selectedWorkspace.id) {
          this.getActivityFeed();
        }
      },
    },

    selectedTab: {
      handler() {
        this.getActivityFeed();
      },
    },
  },

  methods: {
    async getActivityFeed() {
      this.isLoading = true;
      const { error, payload } = await workspaceNew.getActivityFeed(
        this.selectedWorkspace.id,
        this.selectedTab
      );
      this.isLoading = false;

      if (error) {
        this.$alert.error(error);
        return;
      }

      console.log("🚀 ~ getActivityFeed ~ payload:", payload);
      const { data } = payload;
      this.activityFeed = data[0].value;
    },
  },
};
</script>

<template>
  <div id="activity-feed">
    <BaseScrollbar
      :height="`calc(100vh - ${$q.fullscreen.isActive ? '68px' : '260px'})`"
      class="q-pa-md"
    >
      <div class="tabs">
        <div
          v-for="tab in tabs"
          :key="tab"
          class="tab"
          :class="{ selected: selectedTab === tab }"
          @click="selectedTab = tab"
        >
          {{ tab }}
        </div>
      </div>

      <div class="entries">
        <div v-for="entry in activityFeed" :key="entry.itemId" class="entry">
          <div class="created-by">{{ entry.createdBy }}</div>
          <div class="message" v-html="entry.message"></div>
          <div class="created-at">{{ $day.format(entry.createdAt) }}</div>
        </div>
      </div>
    </BaseScrollbar>
  </div>
</template>

<style lang="scss" scoped>
#activity-feed {
  width: 256px;
  background-color: var(--component-bg-color);
  border-radius: 4px;

  .tabs {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 16px;

    .tab {
      padding: 10px 16px;
      border-radius: 4px;
      color: var(--body-text-color-muted);

      &:hover {
        color: var(--body-text-color-muted-hover);
        cursor: pointer;
      }

      &.selected {
        font-weight: 500;
        color: var(--body-text-color);
      }
    }
  }

  .entry {
    margin-bottom: 16px;

    .created-at,
    .created-by {
      font-size: 10px;
      color: var(--icon-color);
      font-weight: 500;
    }

    .created-at {
      text-align: right;
    }

    .message {
      background-color: var(--body-bg-color);
      padding: 8px 12px;
      border-radius: 8px;
      color: var(--icon-color);
    }
  }
}

.theme-light {
  .selected {
    background-color: var(--hover-bg-color-inverted);
  }

  .tab:hover {
    background-color: var(--hover-bg-color-inverted);
  }
}

.theme-dark {
  .selected {
    background-color: var(--hover-bg-color);
  }

  .tab:hover {
    background-color: var(--hover-bg-color);
  }
}
</style>
